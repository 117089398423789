import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import { Button } from '@gik/ui/Button';
import type { LandingPageCTA } from '../types/LandingPageCTA';
import { Image } from '@gik/ui/Image';
import { SvgIcon } from '@gik/ui/SvgIcon';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import { HubspotProvider } from 'next-hubspot';
import { useHubspotForm } from 'next-hubspot';
import Link from '@/components/Link';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';

export interface ICTACardProps {
  cta: LandingPageCTA;
  inverted?: boolean;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4696-8640&m=dev
 */
function CTACardComp({
  cta: { icon, linkIcon, onClickIcon, form, title, content, buttonTitle = 'Read more', buttonLink },
  inverted,
}: ICTACardProps) {
  const bem = useBemCN('cta-card');

  const onCTAButtonLinkClicked = React.useCallback(() => {
    Analytics.fireEvent(AnalyticsEvents.CTAClicked, {
      feature: title,
      location: window.location.pathname,
    });
  }, [title]);

  const onCTAIconLinkClicked = React.useCallback(() => {
    Analytics.fireEvent(AnalyticsEvents.CTAIconClicked, {
      feature: title,
      location: window.location.pathname,
    });

    onClickIcon?.();
  }, [onClickIcon, title]);

  return (
    <Layout {...bem()} direction={inverted ? 'column md:row-reverse' : 'column md:row'}>
      <Layout element="aside" direction={'row'} align={'center'} perpendicular={'center'} flex="0 0 45%">
        <div>
          {icon && (
            <>
              {(linkIcon && buttonLink) || onClickIcon ? (
                <Link href={onClickIcon ? undefined : buttonLink} onClick={onCTAIconLinkClicked}>
                  <Image src={icon} alt={'icon'} className={'tw-rounded tw-shadow-lg'} />
                </Link>
              ) : (
                <Image src={icon} alt={'icon'} className={'tw-rounded tw-shadow-lg'} />
              )}
            </>
          )}
          {form && (
            <HubspotProvider>
              <HubspotForm form={form} />
            </HubspotProvider>
          )}
        </div>
      </Layout>
      <Layout direction={'column'} align={'center'} perpendicular={'start'} gap={6} flex="1 1 55%">
        <h2
          className={
            'tw-m-0 tw-text-neutral-900 tw-font-heading tw-text-4xl tw-font-semibold tw-leading-tight md:tw-text-5xl'
          }
        >
          {title}
        </h2>
        <p className={'tw-m-0 tw-text-neutral-700 tw-font-body tw-text-lg tw-font-normal tw-leading-relaxed'}>
          {content}
        </p>
        {buttonLink && buttonTitle && (
          <Button
            type={'a'}
            href={buttonLink}
            onClick={onCTAButtonLinkClicked}
            append={<SvgIcon Icon={ChevronRightIcon} />}
          >
            {buttonTitle}
          </Button>
        )}
      </Layout>
    </Layout>
  );
}

export const CTACard = withComponentErrorBoundary(CTACardComp);

function HubspotForm({ form: { portalId, formId, region } }: { form: { portalId; formId; region } }) {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId,
    formId,
    region,
    target: '#hubspot-form-wrapper',
  });

  return <div id="hubspot-form-wrapper" />;
}
