import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { Layout } from '@gik/ui/gik/Layout/Layout';
import type { LandingPageFeatures } from '../types/LandingPageFeature';
import { Image } from '@gik/ui/Image';
import Link from '@/components/Link';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';

export interface IFeatureTileProps {
  feature: LandingPageFeatures;
}

/**
 * https://www.figma.com/design/FT2ninjhNiQOXfnOTaZfqy/%F0%9F%91%A8%F0%9F%8F%BC%E2%80%8D%F0%9F%8E%A4-Jade's-workspace-of-asset-creation?node-id=4827-8905&m=dev
 */
function FeatureTileComp({ feature: { icon, title, features, link, onClick } }: IFeatureTileProps) {
  const bem = useBemCN('feature-tile');

  const onFeatureClicked = React.useCallback(() => {
    Analytics.fireEvent(AnalyticsEvents.FeatureClicked, {
      feature: title,
      location: window.location.pathname,
    });

    onClick?.();
  }, [onClick, title]);

  return (
    <Link
      onClick={onFeatureClicked}
      href={link}
      target={link ? '_blank' : undefined}
      rel={link ? 'noopener noreferrer' : undefined}
    >
      <Layout {...bem()} gap={5}>
        <aside
          style={{
            flexShrink: 0,
          }}
        >
          <Layout
            align={'center'}
            perpendicular={'center'}
            className="tw-rounded-full tw-bg-neutral-100 tw-p-4 tw-w-16 tw-h-16 md:tw-w-24 md:tw-h-24"
          >
            <Image src={icon} alt={'Icon'} />
          </Layout>
        </aside>
        <Layout element={'main'} direction={'column'} gap={1}>
          <h3 className={titleClassNames.join(' ')}>{title}</h3>
          <ul>
            {features.map((feature, i) => (
              <li key={i} className={featureClassNames.join(' ')}>
                {feature}
              </li>
            ))}
          </ul>
        </Layout>
      </Layout>
    </Link>
  );
}

const titleClassNames = [
  'tw-m-0',
  'tw-text-neutral-900',
  'tw-font-heading',
  'tw-text-xl',
  'tw-font-bold',
  'tw-leading-tight',
];

const featureClassNames = ['tw-text-neutral-800', 'tw-font-body', 'tw-text-base', 'tw-leading-tight', 'md:tw-text-lg'];

export const FeatureTile = withComponentErrorBoundary(FeatureTileComp);
